import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FootballMoreCs = () => {
  return (
    <section className="more-cs">       
        <div className="cs-more-up">
            <Container>
                <Row>
                    <Col lg={12}><h2 className="sub-title text-center mb-5">More Case Studies</h2></Col>
                    <Col xs={6}>
                        <div className="cs-list-more">
                            <Link to="/case-studies/golazo/">
                                <div className="img-cs">
                                    <StaticImage src="../../../images/case-studies/golazo/golazo-next-prev.png" alt="Golazo UX/UI Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid" width={798} height={197}/>
                                </div>
								<div className="cs-details">
									<div className="cs-more-title"><h3>Golazo</h3></div>
									<p>Industry: Digital Product <br />Platform: Mobile<br /></p>
								</div>                                
                            </Link>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="cs-list-more">
                            <Link to="/case-studies/golazo/">
                                <div className="img-cs">
                                    <StaticImage src="../../../images/case-studies/nomidman/nav-cs-nomidman.jpeg" alt="Nomidman UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={798} height={197}/>
                                </div>
								<div className="cs-details">
									<div className="cs-more-title"><h3>Nomidman</h3></div>
									<p>Industry: Blockchain <br />Platform: Mobile, Web<br /></p>
								</div>                                
                            </Link>
                        </div>
                    </Col>                    
                </Row>
            </Container>
        </div>
    </section>
  )
}

export default FootballMoreCs
