import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballOverview = () => {
  return (
	<section className="meet-the-client pt-0 bg-white">
		<Container>
			<div className="section-primary">
				<Row>
					<Col lg={2} md={4}>
						<h2 className="sub-title">
							Project Overview
						</h2>
					</Col>
					<Col lg={9} md={8} className="offset-lg-1">
						<p className="cs-sub-text first">
							99Football is a football portal that fuels the passion of fans around the world by providing match scores, transfers, table standings and exclusive manager-player interactions. 
						</p>
						<p className="cs-sub-text first">
							Our objective was to design a platform that was visually consistent, easy to navigate, and engaging.
						</p>
						<p className="cs-sub-text">We’ve identified the personas, mapped their journeys, prioritized features and designed a minimal yet aesthetically pleasing interface to deliver a digital experience that was loved by football fans around the globe.</p>
					</Col>
				</Row>
			</div>
		</Container>
	</section>
  )
}

export default FootballOverview
