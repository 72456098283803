import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballMain = () => {
  return (
	<div className="cs-header mobile-left-right-gutter">
		<Container>
			<Row>
				<Col xl={5} md={6} className="offset-xl-1">
					<div className="csh-content">
						<p className="mb-0 font-weight-bold">
                            <Link href="/case-studies/">Case Studies</Link> / <span className="text-white">99Football</span>
                        </p>
						<h1>90 minutes and more of every football match.</h1>
						<p>99Football lets you follow all the news, live scores, transfers, results, fixtures, table standings and more to stay informed about the game.</p>
					</div>
				</Col>
			</Row>
            <div className="right-bg">
                <StaticImage src="../../../images/case-studies/99football/header-img.png" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={316} height={642}/>
            </div>            
		</Container>
	</div>
  )
}

export default FootballMain
