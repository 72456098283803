import React from "react"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import SeoHead from "../../components/global/seoHead"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import FootballMain from "../../components/case-studies/99football/footballMain"
import FootballInfo from "../../components/case-studies/99football/footballInfo"
import FootballOverview from "../../components/case-studies/99football/footballOverview"
import FootballTestimonials from "../../components/case-studies/99football/footballTestimonials"
import FootballGoals from "../../components/case-studies/99football/footballGoals"
import FootballFullWidth1 from "../../components/case-studies/99football/footballFullWidth1"
import FootballFullSolutions from "../../components/case-studies/99football/footballSolutions"
import FootballWhatWeDid from "../../components/case-studies/99football/footballWhatWeDid"
import CsBlankSection from "../../components/case-studies/csBlankSection"
import FootballMoreCs from "../../components/case-studies/99football/footballMoreCs"
import DirectContact from "../../components/global/directContact"

import csFootballFeaturedImage from "../../../static/case-studies/99football/99football-social-share.jpeg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/99football.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csFootballFeaturedImage}
      title="99Football Case Study | UX/UI Design | Sports"
      description="99Football is a digital portal for soccer lovers to get exclusive news on football around the world. Our UX team designed an intuitive user experience for this sports app."
    />
  </> 
)

const FootBall99 = () => {
    return (
      <Layout>
        <Seo
          bid="cs-99-football"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <FootballMain />
        <FootballInfo />
        <FootballOverview />
        <FootballTestimonials />
        <FootballGoals />
        <FootballFullWidth1 />
        <FootballFullSolutions />
        <FootballWhatWeDid />
        <CsBlankSection />
        <FootballMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default FootBall99
