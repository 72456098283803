import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballFullWidth1 = () => {
  return (
	<div className="full-width-bg">
		<Container fluid>
			<Row>
				<Col xs={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/99football/full-width-1.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1946}/>
				</Col>
			</Row>
		</Container>
	</div>
  )
}

export default FootballFullWidth1
