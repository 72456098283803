import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballGoals = () => {
  return (
	<section className="problem-project-goals">
		<Container>
			<div className="section-primary">			
				<Row className="row">
					<Col md={2} className="col-md-2">
						<h2 className="sub-title">The Problem</h2>
					</Col>
					<Col md={9} className="offset-md-1">
						<h3 className="secondary-title">Design a football platform that gave fans complete updates on clubs, players and match stats.</h3>
						<p className="cs-sub-text">
							There were hundreds of football-oriented websites and apps. But most of them were cluttered with information, stuffed with ads, difficult to navigate, and only provided some of the stats. A well-designed platform that provides complete match stats, player information, schedules, and interviews were missing. This is the space 99Football aims to capitalize on. 
						</p>
					</Col>
				</Row>
			</div>			
		</Container>
	</section>    
  )
}

export default FootballGoals
