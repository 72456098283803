import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballTestimonials = () => {
  return (
	<section className="cs-testimonials">
		<Container>			
			<Row className="justify-content-center">
				<Col lg={7}>
					<div className="content text-center">
						<h2 className="sub-title text-white">"We wouldn’t have shipped it on time without your entire team’s commitment"</h2>
						<p className="cs-sub-text first text-white">
							We approached WowMakers to improve the user experience of 99Football application. Our platform has been diligently developed and rigorously reiterated to fulfill all the needs of a football lover. As always there were last minute changes which the WowMakers design team incorporated into the wireframes in no time at all! We are definitely coming to you for future projects.
						</p>
						<div className="client">
							<p className="text-white">Emma Chapman, Chief Product Officer, 99Football</p>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	</section>
  )
}

export default FootballTestimonials
