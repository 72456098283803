import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballWhatWeDid = () => {
  return (
	<section className="what-we-did">
		<Container>
			<Row>
				<Col md={3}>
					<h2 className="sub-title text-white">Services Offered</h2>
				</Col>
				<Col md={6} className="offset-md-3">
					<p className="left-bullet football99 text-white">Usability Research</p>
					<p className="left-bullet football99 text-white">User Interface Design</p>
					<p className="left-bullet football99 text-white">iOS and Android App Development</p>
					<p className="left-bullet football99 text-white">Testing</p>
					<p className="left-bullet football99 text-white">Design System</p>
				</Col>
			</Row>
		</Container>
	</section>
  )
}

export default FootballWhatWeDid
