import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FootballFullSolutions = () => {
  return (
	<section className="our-solutions">
		<Container className="container">
			<div className="section-primary">			
				<Row className="row">
					<Col md={2}>
						<h2 className="sub-title">Our Solutions</h2>
					</Col>
					<Col md={9} className="offset-md-1">
						<h3 className="secondary-title">Swift Onboarding</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Everything that will help you get to the kick-off, in just 3 steps.
						</p>
                        <StaticImage src="../../../images/case-studies/99football/swift-onboarding.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>
						<h3 className="secondary-title mt-5">Stay Up-to-date</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Quick access to your favorite championship, table standings and all the fixtures so that you don’t miss any matchdays!
						</p>
                        <StaticImage src="../../../images/case-studies/99football/stay-up-to-date.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>
						<h3 className="secondary-title mt-5">Discover & Inform</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Clean, minimal and intuitive user interface that displays the latest news, articles, trivia, game reviews & summary from the football pundits.
						</p>
                        <StaticImage src="../../../images/case-studies/99football/discover-Inform.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>
						<h3 className="secondary-title mt-5">Fixture Tracker</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Track and monitor your teams’ progress by switching between played and upcoming fixtures.
						</p>
                        <StaticImage src="../../../images/case-studies/99football/fixture-tracker.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>						
						<h3 className="secondary-title mt-5">Insights From All Corners</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Detailed statistics, manager interviews and match reports accessible to you after the final whistle.
						</p>
                        <StaticImage src="../../../images/case-studies/99football/insights-corners.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>
						<h3 className="secondary-title mt-5">Live on the Pitch With Your Team</h3>
						<p className="cs-sub-text gutter-bottom-small">
							Connect to the live broadcast and get quick updates of key moments during the match.
						</p>
                        <StaticImage src="../../../images/case-studies/99football/live-team.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>						
						<h3 className="secondary-title mt-5">Assess Player Performance</h3>
						<p className="cs-sub-text gutter-bottom-small">Evaluate player success with detailed stats of the player’s contributions and in-depth analysis of the game.</p>						
                        <StaticImage src="../../../images/case-studies/99football/assess-player-performance.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>						
						<h3 className="secondary-title mt-5">Know the Leaderboard</h3>
						<p className="cs-sub-text gutter-bottom-small">Get info on the leading goalscorer, safest goal-keeping hands and who is playing better at the moment</p>
                        <StaticImage src="../../../images/case-studies/99football/know-the-leaderboard.jpeg" alt="99Football UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1080}/>						
					</Col>
				</Row>
			</div>			
		</Container>
	</section>    
  )
}

export default FootballFullSolutions
